<template>
  <div
    class="step-wrapper"
    :class="{ activeStep: active }"
  >
    <!-- <button
    style="width: 100%;"
      :disabled="firststep"
      :style="[firststep ? {'opacity': '0'} : {'opacity': '0'}]"
    >Back</button> -->
    <button
      v-if="!laststep"
      class="bg-[#58C1E1] rounded-[100px] py-3 px-12 text-white text-sm font-bold"
      style="width: 100%"
      :class="{ red: calendar }"
      :disabled="!calendar"
      @click="nextStep()"
    >
      Continue
    </button>
    <button
      v-if="laststep"
      class="bg-[#58C1E1] rounded-[100px] py-3 px-12 text-white text-sm font-bold"
      style="width: 100%"
      :disabled="finishStepStatus"
      @click="submitStep()"
    >
      Finish
    </button>
  </div>
</template>

<script>
  export default {
    props: ["step", "stepcount", "currentstep", "calendar", "finishStepStatus"],

    computed: {
      active: function () {
        return this.step.id == this.currentstep;
      },

      firststep: function () {
        return this.currentstep == 1;
      },

      laststep: function () {
        return this.currentstep == this.stepcount;
      },
    },

    methods: {
      nextStep: function () {
        this.$emit("step-change", this.currentstep + 1);
        // console.log(this.calendar);
      },

      lastStep: function () {
        this.$emit("step-change", this.currentstep - 1);
      },

      submitStep: function () {
        this.$emit("step-submit", this.currentstep + 1);
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>

