<template>
  <div>
    <div class="flex flex-wrap mx-3 overflow-hidden">
      <div class="my-3 px-1 w-3/12 overflow-hidden">
        <VueSlickCarousel
          v-if="Object.keys(imagesObject).length > 0"
          class="slick-vertical-slider"
          ref="c2"
          :asNavFor="$refs.c1"
          :focusOnSelect="true"
          :vertical="true"
          :verticalSwiping="false"
          :slidesToShow="3"
          :slidesToScroll="1"
          :style="{ height: '280px' }"
        >
          <img
            v-for="(image, index) in imagesObject"
            :key="index"
            style="height: 100px; width: 100%; object-fit: contain"
            :src="image"
            alt=""
          />
        </VueSlickCarousel>
      </div>

      <div class="my-3 px-1 w-9/12 overflow-hidden">
        <VueSlickCarousel
          v-if="Object.keys(imagesObject).length > 0"
          class="slick-horizontal-slider"
          ref="c1"
          :asNavFor="$refs.c2"
          :focusOnSelect="true"
          :style="{ height: '280px' }"
        >
          <img
            v-for="(image, index) in imagesObject"
            :key="index"
            style="height: 200px; width: 100%; object-fit: contain"
            :src="image"
            alt=""
          />
        </VueSlickCarousel>
      </div>
    </div>

    <!-- <carousel v-if="Object.keys(imagesObject).length > 0" :perPage="1">
      <slide v-for="(image, index) in imagesObject" :key="index">
        <img
          style="height: 200px; width: 100%; object-fit: contain"
          :src="image"
        />
      </slide>
    </carousel> -->
    <!-- <carousel v-else><slide></slide></carousel> -->
    <div
      v-if="selectedSection == 'details' && selectedSectionLocal == 'details'"
      class="productPageDetails"
    >
      <div class="flex justify-between align-top mb-1 mx-auto">
        <div class="flex flex-col justify-center items-center">
          <img
            v-if="storeProduct.from == 'shop'"
            @click="addProductToGoals"
            class="w-[30px] h-[30px]"
            src="../../assets/images/icon-add-goal.svg"
            alt="Add Goald"
          />
          <p v-if="storeProduct.from == 'shop'" class="text-[10px] mt-1">
            Goals
          </p>
        </div>
        <div
          class="font-bold text-center leading-tight text-sm px-4"
          style="font-size: 14px !important"
        >
          {{ storeProduct.name }}
        </div>
        <div class="flex flex-col justify-center items-center">
          <img
            v-if="storeProduct.from == 'shop'"
            @click="openSheetWishlistOcassions"
            class="w-[30px] h-[30px]"
            src="../../assets/images/icon-add-wishlist.svg"
            alt="Add Wishlist"
          />
          <p v-if="storeProduct.from == 'shop'" class="text-[10px] mt-1">
            Wishlist
          </p>
        </div>
      </div>

      <div
        class="flex flex-col justify-center items-center mt-4"
        :style="[
          storeProduct.from == 'wishlist'
            ? { 'margin-bottom': '20px' }
            : { 'margin-bottom': '20px' },
        ]"
      >
        <div
          class="text-xl font-bold"
          style="font-size: 1.25rem"
          :style="{ color: priceColor() }"
        >
          <small class="text-sm">&pound;</small
          >{{ formatPrice(storeProduct.price) }}
        </div>
        <div
          v-if="storeProduct.stars !== null"
          class="shopProductDetails__right__stars mt-2"
        >
          <star-rating
            :rating="Number(storeProduct.stars)"
            :border-width="3"
            :read-only="true"
            :show-rating="false"
            :star-size="17"
            inactive-color="#EBEBEB"
            active-color="#f6a42c"
            border-color="#EBEBEB"
            :star-points="[
              23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46,
              19, 31, 17,
            ]"
            :rounded-corners="true"
          >
          </star-rating>
        </div>
        <div
          v-if="storeProduct.from == 'goals'"
          style="
            font-weight: 600;
            margin-top: 10px;
            color: #b9b6b6;
            font-weight: 400;
          "
        >
          <div style="font-size: 13px">
            Savings Added: £{{
              storeProduct.savingsAdded ? storeProduct.savingsAdded : 0
            }}
          </div>
        </div>
      </div>

      <div v-if="storeProduct.from == 'goals'" class="mb-6">
        <div class="flex justify-center items-center">
          <AppButton
            v-if="storeProduct.cashYouMoreNeed != 0"
            text="Add Funds"
            color="blue"
            :mini="true"
            class="font-bold w-auto py-2 px-6 rounded-full text-sm"
            style="min-width: 140px; width: 140px"
            @click="openSheetTransferFunds"
          >
          </AppButton>
          <AppButton
            v-if="
              storeProduct.cashYouMoreNeed == 0 ||
              storeProduct.cashYouMoreNeed == null ||
              storeProduct.cashYouMoreNeed == undefined
            "
            text="Buy"
            color="blue"
            :mini="true"
            class="font-bold w-auto py-2 px-6 rounded-full text-sm"
            style="min-width: 140px; width: 140px"
            @click="buyProduct()"
          >
          </AppButton>
          <!-- <AppButton
            v-else
            text="Buy"
            style="width: 100% !important"
            class="shopProductDetails__right__buttons"
            color=""
            :mini="true"
          >
          </AppButton> -->
        </div>
      </div>

      <div
        v-if="storeProduct.from == 'wishlist'"
        class="flex justify-center items-center mb-6"
      >
        <AppButton
          v-if="
            storeProduct.cashYouMoreNeed == 0 ||
            storeProduct.cashYouMoreNeed == null
          "
          class="font-bold w-auto py-2 px-6 rounded-full text-sm"
          style="min-width: 140px; width: 140px"
          text="Buy"
          color="blue"
          :mini="true"
          @click="buyProduct()"
        >
        </AppButton>
        <AppButton
          v-else
          class="font-bold w-auto py-2 px-6 rounded-full text-sm"
          style="min-width: 140px; width: 140px"
          text="Buy"
          :mini="true"
        >
        </AppButton>
      </div>

      <div
        v-if="storeProduct.from == 'shop'"
        class="flex justify-center items-center mb-6"
      >
        <!-- <div
          class="shopProductDetails__right__button"
        >
          <AppButton
            text="Add to Goals"
            color="red"
            :mini="true"
            class="shopProductDetails__right__buttons"
            @click="addProductToGoals"
          >
          </AppButton>
          <AppButton
            text="Add to Wishlist"
            color="blue"
            :mini="true"
            class="shopProductDetails__right__buttons"
            @click="openSheetWishlistOcassions"
          >
          </AppButton>
        </div> -->

        <AppButton
          v-if="
            storeProduct.cashYouMoreNeed == 0 ||
            storeProduct.cashYouMoreNeed == null
          "
          text="Buy"
          color="blue"
          :mini="true"
          class="font-bold w-auto py-2 px-6 rounded-full text-sm"
          style="min-width: 140px; width: 140px"
          @click="buyProduct()"
        >
        </AppButton>
        <AppButton
          v-else
          text="Buy"
          class="font-bold w-auto py-2 px-6 rounded-full text-sm"
          style="min-width: 140px; width: 140px"
          color=""
          :mini="true"
        >
        </AppButton>
      </div>

      <div class="productPageDetails__description">Description</div>
      <div style="font-size: 13px">
        <span v-if="!$store.state.readMore"
          >{{ storeProduct.description.slice(0, 100) }}
        </span>
        <span
          v-if="$store.state.readMore"
          v-html="storeProduct.description"
        ></span>
        <span
          v-if="!$store.state.readMore && storeProduct.description.length > 100"
          @click="activateReadMore"
          class="readMore"
          style="display: block; margin-top: 5px"
        >
          Read more...
        </span>
        <span
          v-else-if="$store.state.readMore"
          @click="activateReadMore"
          class="readMore"
          style="display: block; margin-top: 5px"
        >
          Read less
        </span>
      </div>

      <!-- <div
        v-if="storeProduct.givenStars == 0"
        class="shopProductDetails__right__info leave-rating"
        style="margin-bottom: 50px"
      >
        Leave your rating
        <star-rating
          :border-width="4"
          inactive-color="#ffffff"
          active-color="#ffa41d"
          border-color="#ffa41d"
          :rounded-corners="true"
          :show-rating="false"
          :star-size="30"
          class="shopProductDetails__right__stars__star"
          @rating-selected="giveRating"
        >
        </star-rating>
      </div> -->
    </div>
    <Sheet
      :show.sync="sheetWishlistOcassions"
      :on-close="closeSheetWishlistOcassions"
    >
      <h4 style="text-align: center; font-weight: 600; margin-top: 20px">
        Choose an occasion
      </h4>
      <div
        class="missionDays"
        style="padding: 20px 0 20px 10px; background: #ffffff"
      >
        <div
          v-for="(occasion, index) in getOcassions"
          :key="index"
          class="missionDays__item missionDays__item__active"
          @click="selectOcassion(occasion.id)"
        >
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <span class="missionDays__item-username"
              ><img
              alt=""
                :src="occasion.image_link_encode"
                style="
                  width: 10px;
                  height: 10px;
                  filter: invert(1);
                  margin-right: 5px;
                "
              />{{ occasion.name }}</span
            >
          </div>
        </div>
      </div>
    </Sheet>

    <Sheet :show.sync="sheetTransferFunds" :on-close="closeSheetTransferFunds">
      <!-- <StepNavigation :steps="steps" :currentstep="currentstep">
      </StepNavigation> -->

      <div v-if="currentstep == 1">
        <h1 class="transferInfoTitle">Transfer money from:</h1>

        <div class="appTransferFunds__wallets" v-if="user.accounts">
          <!-- <h1
            class="mobileWall walletsTitle"
            style="margin-bottom: 0; margin-top: 0"
          >
            Wallets &amp; Goals
          </h1> -->
          <div
            class="walletScroll recent_container"
            style="scroll-padding: 1.5rem; margin-top: -10px"
          >
            <TransferWallets
              v-for="(wallet, index) in user.accounts.filter(
                (item) => (item.name == 'main' || item.name == 'close') && item.current_balance > 0
              )"
              :key="index"
              :wallet="wallet"
              @click.native="selectTransfer(wallet.id, wallet, 'wallet')"
              :style="[
                selectedTransferAccount == wallet.id
                  ? {}
                  : { background: '#ccc', filter: 'grayscale(100%)' },
              ]"
              class="transferWallet walletScroll__item"
              style="margin-right: 10px;min-height: 130px;"
            />

            <div
              class="goal-item walletScroll__item shadow"
              style="
                flex-direction: column;
                align-items: flex-start;
                padding: 15px;
                border-radius: 13px;
                margin-right: 10px;
                min-height: 130px;
              "
              v-for="goal in getGoals.filter(
                (item) => item.id !== storeProduct.id && item.account.current_balance > 0
              )"
              :key="goal.id"
              @click="
                goal.account.current_balance == 0
                  ? null
                  : selectTransfer(goal.account.id, goal, 'goal')
              "
              :style="[
                selectedTransferAccount == goal.account.id
                  ? {}
                  : { background: '#ccc', filter: 'grayscale(100%)' },
              ]"
              :class="{ noFundsGoal: goal.account.current_balance == 0 }"
            >
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                "
              >
                <div
                  class="goal-img"
                  style="
                    width: 65px;
                    height: 65px;
                    min-width: 65px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <img
                    style="width: 100%"
                    :src="resolveImage(goal.product.default_picture_link)"
                    alt=""
                  />
                </div>
                <div
                  style="
                    font-size: 30px;
                    font-weight: 700;
                    margin-right: 25px;
                    line-height: 21px;
                  "
                >
                  <span class="poundSign">&pound;</span
                  >{{ formatPrice(goal.product.price) }}

                  <div style="font-size: 16px; font-weight: 600">
                    <small
                      >&pound;{{
                        formatPrice(goal.account.current_balance)
                      }}
                      Available</small
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="currentstep == 2" class="addMissionExplChild">
        <h1
          class="transferInfoTitle"
          style="font-size: 16px; margin-bottom: 10px; font-weight: 600"
        >
          How much would you like to transfer?
        </h1>
        <div
          v-if="
            getSpendWalletBalance == 0 &&
            getGoals.filter((item) => item.account.current_balance > 0)
              .length == 0
          "
          class="noFundsError"
        >
          You do not have enough funds to transfer!
        </div>
        <div v-else>
          <div class="amountToTransfer">
            <div style="font-size: 12px">
              You have
              <span>&pound;{{ formatPrice(transferAll) }}</span> available for
              transfer
            </div>

            <div
            v-if="getSpendWalletBalance >= storeProduct.cashYouMoreNeed ||
                getSaveWalletBalance >= storeProduct.cashYouMoreNeed"
              class="transferAllAction"
              @click="transferAllSum(storeProduct.cashYouMoreNeed)"
            >
              I want to transfer &pound;{{ formatPrice(storeProduct.cashYouMoreNeed) }}
            </div>
            <div v-else class="transferAllAction" @click="transferAllSum(transferAll)">
            I want to transfer all
          </div>
          </div>
          <AppErrorMessage :message="morefundsNeeded" />
          <input
            class="guardian-login-form__input"
            placeholder="0.00"
            type="email"
            spellcheck="false"
            v-model="transferObj.sum"
            style="width: 100%; margin-bottom: 15px"
          />
          <!-- <AppInput
            type="number"
            placeholder="0.00"
            class="appCheckout__game__input"
            v-model="transferObj.sum"
            @input="validateFunds"
            style="margin-bottom: 15px"
          /> -->
          <AppErrorMessage :message="error" style="display: none" />
          <AppButton
            v-if="transferType == 'goal'"
            text="Transfer"
            :color="validateFunds"
            :mini="true"
            @click="transferAction"
          />
          <AppButton
            v-if="transferType == 'wallet'"
            text="Transfer"
            :color="validateFunds"
            :mini="true"
            @click="transferActionFromWallet"
          />
        </div>
      </div>
      <div v-if="currentstep == 3" class="addMissionExplChild">
        <h1 class="transferInfoTitleSuccess">Success!</h1>
        <p class="successDesc">
          You've transferred
          <span class="redSuccess"
            >&pound;{{ formatPrice(transferObj.sum) }}</span
          >
          from
          <span class="redSuccess" v-if="finishTransferFrom.name == 'close'"
            >Save Wallet</span
          >
          <span class="redSuccess" v-else-if="finishTransferFrom.name == 'main'"
            >Spend Wallet</span
          >
          <span class="redSuccess" v-else>{{
            finishTransferFrom.product.name.substring(0, 8) + ".."
          }}</span>
          to
          <span class="redSuccess" v-if="finishTransferTo.name == 'close'"
            >Save Wallet</span
          >
          <span class="redSuccess" v-else-if="finishTransferTo.name == 'main'"
            >Spend Wallet</span
          >
          <span class="redSuccess" v-else>{{
            finishTransferTo.product.name.substring(0, 8) + ".."
          }}</span>
        </p>
      </div>

      <div v-if="currentstep <= 1">
        <StepControls
          v-for="(step, index) in steps"
          :step="step"
          :key="index"
          :calendar="checkPassStep"
          :stepcount="steps.length"
          :currentstep="currentstep"
          @step-change="stepChanged"
          @step-submit="transferAction"
        >
        </StepControls>
      </div>
    </Sheet>
  </div>
</template>

<script>
  import AppButton from "../../components/AppButton";
  import Sheet from "../../components/Sheet";
  // import { Carousel, Slide } from "vue-carousel";
  import StepControls from "../../components/wizard/StepControls";
  import TransferWallets from "../../components/TransferWallets";
  import AppErrorMessage from "../../components/AppErrorMessage";
  // import AppInput from "@/components/AppInput";
  import axios from "axios";
  import status from "../../utils/statusCode";
  import config from "../../config";
  import { mapGetters, mapActions } from "vuex";

  import VueSlickCarousel from "vue-slick-carousel";
  import "vue-slick-carousel/dist/vue-slick-carousel.css";
  import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

  export default {
    props: {
      id: {},
      name: {
        type: String,
        default: "",
      },
      description: {
        type: String,
        default: "",
      },
      price: {},
      progressValue: {},
      stars: {},
      savingsAdded: {},
      defaultPicture: {
        type: String,
        default: "",
      },
      images: {
        type: Array,
        default: () => [],
      },
      cashYouMoreNeed: {},
      productId: {
        type: Number,
        default: 0,
      },
      selectedSection: {
        type: String,
        default: "details",
      },
      givenStars: {
        type: Number,
        default: 0,
      },
      wish: {},
    },
    components: {
      AppButton,
      // Carousel,
      // Slide,
      Sheet,
      StepControls,
      TransferWallets,
      AppErrorMessage,
      VueSlickCarousel,
      // AppInput,
    },
    data() {
      return {
        get storeProduct() {
          return JSON.parse(localStorage.getItem("storeProduct")) || 0;
        },
        // storeProduct: JSON.parse(localStorage.getItem("storeProduct")),
        error: "",
        ocassions: [],
        selectedOccasionId: null,
        imagesBorders: ["#36D5F2", "#E5E5E5", "#E5E5E5", "#E5E5E5"],
        selectedSectionLocal: "details",
        wishlistObject: [],
        imagesObject: [],
        left: false,
        sheetWishlistOcassions: false,
        sheetTransferFunds: false,
        currentstep: 1,
        selectedTransferAccount: null,
        transferAll: null,
        transferType: null,
        passStep: false,
        finishTransferTo: null,
        selectedTransferToAccount: null,
        transferObj: {
          from: null,
          to: null,
          sum: null,
        },
        steps: [
          {
            id: 1,
            title: "STEP 1",
            icon_class: "fa fa-map-marker",
          },
          {
            id: 2,
            title: "STEP 2",
            icon_class: "fa fa-paper-plane",
          },
          {
            id: 3,
            title: "STEP 3",
            icon_class: "fa fa-paper-plane",
          },
        ],
      };
    },
    created() {
      const user = {
            userId: localStorage.getItem("children_user_id"),
            token: localStorage.getItem("children_token"),
            family_id_check: localStorage.getItem("children_family_id"),
        };
        this.fetchProfile();
        this.setupUser(user);
      this.fetchOcassions();

      if (localStorage.getItem("storeProduct")) {
        this.wishlistObject = JSON.parse(localStorage.getItem("storeProduct"));
        console.log(this.wishlistObject);
        this.wishlistObject.images.forEach((image) => {
          this.imagesObject.push(this.resolveImage(image));
        });
      } else {
        this.$router.replace("/wishlist");
      }

      // this.$refs.product__slider.slick({
      //   autoplay: false,
      //   autoplaySpeed: 1000,
      //   speed: 600,
      //   draggable: true,
      //   infinite: true,
      //   slidesToShow: 1,
      //   slidesToScroll: 1,
      //   arrows: false,
      //   dots: false,
      //   vertical: true,
      //   verticalSwiping: true,
      //   asNavFor: ".nav__slider",
      // });
      // this.$refs.nav__slider.slick({
      //   slidesToShow: 3,
      //   slidesToScroll: 1,
      //   asNavFor: ".product__slider",
      //   centerMode: true,
      //   focusOnSelect: true,
      //   vertical: true,
      //   arrows: false,
      //   verticalSwiping: true,
      // });
    },
    computed: {
      ...mapGetters([
        "getOcassions",
        "user",
        "getGoals",
        "getSpendWalletBalance",
      ]),
      // storeProduct() {
      //   return JSON.parse(localStorage.getItem("storeProduct"));
      // },
      checkPassStep() {
        return this.passStep;
      },
      morefundsNeeded() {
        if (this.transferObj.sum > this.transferAll) {
          return "You do not have enough funds available!";
        } else {
          return "";
        }
      },
      validateFunds() {
        if (
          this.transferObj.sum > 0 &&
          this.transferObj.sum <= this.transferAll
        ) {
          return "blue";
        } else {
          return "";
        }
      },
    },
    methods: {
      ...mapActions([
        "addGoal",
        "setRating",
        "fetchOcassions",
        "addWishlist",
        "fetchGoals",
        "transferFoundsFromMainToGoal",
        "transferFoundsFromGoalToGoal",
        "TOAST",
        "fetchSpendWalletAndGoalsBalance",
        "fetchProfile",
        "setupUser",
      ]),
      transferAction() {
        const details = {
          goal_account_id_src: this.selectedTransferAccount,
          goal_account_id_dest: this.storeProduct.goalAccountId,
          sum: this.transferObj.sum,
          name: "Transfer funds from goal to goal",
          description: "Transfer funds from goal to goal",
        };

        const token = localStorage.getItem("children_token");
        const userId = localStorage.getItem("children_user_id");
        const familyIdChk = localStorage.getItem("children_family_id");
        axios
          .post("/operations/transferFromGoalToGoal", {
            userId: userId,
            token: token,
            family_id_check: familyIdChk,
            goal_account_id_src: details.goal_account_id_src,
            goal_account_id_dest: details.goal_account_id_dest,
            sum: details.sum,
            name: details.name,
            description: details.description,
          })
          .then((response) => {
            const { statusCode } = response.data;
            if (status.success(statusCode)) {
              this.$store.commit("TRANSFER_FOUNDS_FROM_GOAL_TO_GOAL", details);

              axios
                .get(
                  "/goalsDashboard/children?" +
                    "userId=" +
                    userId +
                    "&token=" +
                    token +
                    "&family_id_check=" +
                    familyIdChk
                )
                .then(
                  function (response) {
                    const { statusCode } = response.data;

                    if (status.success(statusCode)) {
                      this.$store.commit("FETCH_GOALS", response.data);

                      var item = this.getGoals.find(
                        (item) => item.id === this.storeProduct.id
                      );

                      const productObj = {
                        from: "goals",
                        selectedSection: "details",
                        name: item.product.name,
                        productId: item.product.id,
                        description: item.product.description,
                        price: Number(item.product.price).toFixed(2),
                        stars: Number(item.product.stars),
                        defaultPicture: item.product.default_picture_link,
                        images: this.getProductImages(item.product),
                        cashYouMoreNeed:
                          item.cash_u_need_more != null ||
                          item.cash_u_need_more != undefined
                            ? Number(item.cash_u_need_more)
                            : 0,
                        id: item.id,
                        savingsAdded: Number(
                          item.account.current_balance
                        ).toFixed(2),
                        goalAccountId: item.account.id,
                      };

                      localStorage.setItem(
                        "storeProduct",
                        JSON.stringify(productObj)
                      );
                      this.fetchSpendWalletAndGoalsBalance();
                      this.closeSheetTransferFunds();
                      // this.$router.go(this.$router.currentRoute);
                    } else if (status.invalidToken(statusCode)) {
                      this.TOAST(
                        "Something went wrong! Please contact our support team."
                      );
                    } else if (status.error(statusCode)) {
                      this.TOAST(
                        "Something went wrong! Please contact our support team."
                      );
                    }
                  }.bind(this)
                )
                .catch(function (error) {
                  this.TOAST(
                    "Something went wrong! Please contact our support team."
                  );
                  throw error;
                });
            } else if (status.invalidToken(statusCode)) {
              this.TOAST(
                "Something went wrong! Please contact our support team."
              );
            } else if (status.error(statusCode)) {
              this.TOAST(
                "Something went wrong! Please contact our support team."
              );
            }
          })
          .catch((error) => {
            this.TOAST("Something went wrong! Please contact our support team.");
            throw error;
          });

        // this.transferFoundsFromGoalToGoal(details);
        // this.closeSheetTransferFunds();
      },
      transferActionFromWallet() {
        const details = {
          main_account_id: this.selectedTransferAccount,
          goal_account_id: this.storeProduct.goalAccountId,
          sum: this.transferObj.sum,
          name: "Transfer funds from spend wallet to goal",
          description: "Transfer funds from spend wallet to goal",
        };

        const token = localStorage.getItem("children_token");
        const userId = localStorage.getItem("children_user_id");
        const familyIdChk = localStorage.getItem("children_family_id");
        axios
          .post("/operations/transferFromMainToGoal", {
            userId: userId,
            token: token,
            family_id_check: familyIdChk,
            main_account_id: details.main_account_id,
            goal_account_id: details.goal_account_id,
            name: details.name,
            sum: details.sum,
            description: details.description,
          })
          .then((response) => {
            const { statusCode } = response.data;
            if (status.success(statusCode)) {
              this.$store.commit("TRANSFER_FOUNDS_FROM_MAIN_TO_GOAL", details);

              axios
                .get(
                  "/goalsDashboard/children?" +
                    "userId=" +
                    userId +
                    "&token=" +
                    token +
                    "&family_id_check=" +
                    familyIdChk
                )
                .then(
                  function (response) {
                    const { statusCode } = response.data;

                    if (status.success(statusCode)) {
                      this.$store.commit("FETCH_GOALS", response.data);

                      var item = this.getGoals.find(
                        (item) => item.id === this.storeProduct.id
                      );
                      // console.log("item: ", item);
                      const productObj = {
                        from: "goals",
                        selectedSection: "details",
                        name: item.product.name,
                        productId: item.product.id,
                        description: item.product.description,
                        price: Number(item.product.price).toFixed(2),
                        stars: Number(item.product.stars),
                        defaultPicture: item.product.default_picture_link,
                        images: this.getProductImages(item.product),
                        cashYouMoreNeed:
                          item.cash_u_need_more != null ||
                          item.cash_u_need_more != undefined
                            ? Number(item.cash_u_need_more)
                            : 0,
                        id: item.id,
                        savingsAdded: Number(
                          item.account.current_balance
                        ).toFixed(2),
                        goalAccountId: item.account.id,
                      };

                      localStorage.setItem(
                        "storeProduct",
                        JSON.stringify(productObj)
                      );
                      this.fetchSpendWalletAndGoalsBalance();
                      this.closeSheetTransferFunds();
                      // this.$router.go(this.$router.currentRoute);
                    } else if (status.invalidToken(statusCode)) {
                      this.TOAST(
                        "Something went wrong! Please contact our support team."
                      );
                    } else if (status.error(statusCode)) {
                      this.TOAST(
                        "Something went wrong! Please contact our support team."
                      );
                    }
                  }.bind(this)
                )
                .catch(function (error) {
                  this.TOAST(
                    "Something went wrong! Please contact our support team."
                  );
                  throw error;
                });

              // this.fetchGoals();

              // setTimeout(() => {
              //   console.log(this.getGoals);
              //   var item = this.getGoals.find(
              //     (item) => item.id === this.storeProduct.id
              //   );
              //   console.log("item: ", item);
              //   const productObj = {
              //     from: "goals",
              //     selectedSection: "details",
              //     name: item.product.name,
              //     productId: item.product.id,
              //     description: item.product.description,
              //     price: Number(item.product.price).toFixed(2),
              //     stars: Number(item.product.stars),
              //     defaultPicture: item.product.default_picture_link,
              //     images: this.getProductImages(item.product),
              //     cashYouMoreNeed:
              //       item.cash_u_need_more != null ||
              //       item.cash_u_need_more != undefined
              //         ? Number(item.cash_u_need_more)
              //         : 0,
              //     id: item.id,
              //     savingsAdded: Number(item.account.current_balance).toFixed(2),
              //     goalAccountId: item.account.id,
              //   };

              //   localStorage.setItem("storeProduct", JSON.stringify(productObj));
              //   this.fetchSpendWalletAndGoalsBalance();
              // }, 3000);
              // this.closeSheetTransferFunds();
            } else if (status.invalidToken(statusCode)) {
              this.TOAST(
                "Something went wrong! Please contact our support team."
              );
            } else if (status.error(statusCode)) {
              this.TOAST(
                "Something went wrong! Please contact our support team."
              );
            }
          })
          .catch((error) => {
            this.TOAST("Something went wrong! Please contact our support team.");
            throw error;
          });

        // console.log("transfer from goal");
        // this.transferFoundsFromMainToGoal(details);

        // location.reload()
      },
      getProductImages(product) {
        if (product == null) return [];
        var pictures = [];
        if (product.default_picture_link)
          pictures[0] = product.default_picture_link;
        if (product.picture1_link) pictures[1] = product.picture1_link;
        if (product.picture2_link) pictures[2] = product.picture2_link;
        if (product.picture1_link) pictures[3] = product.picture2_link;
        return pictures;
      },
      transferAllSum(amount) {
        // this.transferObj.sum = this.storeProduct.cashYouMoreNeed;
        this.transferObj.sum = amount;
      },
      selectTransferTo(id, item) {
        this.selectedTransferToAccount = id;
        this.finishTransferTo = item;
        this.transferObj.to = id;
        this.passStep = true;
        // console.log("transfer to id");
        // console.log(id);
      },
      selectTransfer(id, item, type) {
        if (item.current_balance > 0 || item.account?.current_balance > 0) {
          this.selectedTransferAccount = id;
          this.transferType = type;

          // console.log("transfer from id");
          // console.log(id);
          // console.log(item);
          // console.log(type);

          this.passStep = true;

          if (item.account != undefined) {
            // console.log("este");
            this.transferAll = item.account.current_balance;
          } else {
            this.transferAll = item.current_balance;
          }
        }
        // console.log(this.transferAll);
      },
      stepChanged: function (step) {
        // this.currentstep = step;
        if (step == 1) {
          this.passStep = false;
          this.currentstep = step;
        } else {
          this.passStep = true;
        }

        this.currentstep = step;

        if (step == 2) {
          this.currentstep = step;
          this.passStep = false;
        }
      },

      openSheetWishlistOcassions() {
        this.sheetWishlistOcassions = true;
      },
      closeSheetWishlistOcassions() {
        this.sheetWishlistOcassions = false;
      },
      openSheetTransferFunds() {
        this.currentstep = 1;
          this.selectedTransferAccount = null;
          this.transferAll = null;
          this.transferType = null;
          this.passStep = false;
          this.transferObj = {
            from: null,
            to: null,
            sum: null,
          };
          this.fetchGoals(false);
        this.sheetTransferFunds = true;
        // console.log(this.getGoals);
        // var item = this.getGoals.find((item) => item.id === this.storeProduct.id);
        // console.log(item);
      },
      closeSheetTransferFunds() {
        this.sheetTransferFunds = false;
      },
      giveRating() {
        const details = {
          memberId: this.storeProduct.productId,
          stars: this.storeProduct.stars,
          type: "product",
        };
        // if(stars >= 1 && stars <= 5)
        this.setRating(details);
      },
      resolveImage(src) {
        return config.HOST + src;
      },
      activateReadMore() {
        this.$store.state.readMore = !this.$store.state.readMore;
      },
      switchImage(imgsrc, index) {
        this.showImage = imgsrc;

        this.imagesBorders[0] = "#E5E5E5";
        this.imagesBorders[1] = "#E5E5E5";
        this.imagesBorders[2] = "#E5E5E5";
        this.imagesBorders[3] = "#E5E5E5";
        this.imagesBorders[index] = "#36D5F2";
      },
      selectOcassion(ocassionId) {
        this.selectedOccasionId = ocassionId;
        // console.log("ocassion id");
        // console.log(ocassionId);
        // console.log("ocassion id");

        if (this.selectedOccasionId) {
          const details = {
            product_id: this.storeProduct.productId,
            occasion_id: this.selectedOccasionId,
            name: "Add wishlist from shop section",
          };
          this.addWishlist(details);
          this.error = "";
          this.selectedOccasionId = null;
          this.sheetWishlistOcassions = false;
        } else {
          this.error = "You must select an occasion!";
        }
      },
      priceColor() {
        if (
          this.storeProduct.cashYouMoreNeed == null ||
          this.storeProduct.cashYouMoreNeed == 0
        )
          return "#36D5F2";
        return "#36D5F2";
      },
      addProductToGoals() {
        // if (this.selectedOccasionId) {
        const details = {
          product_id: this.storeProduct.productId,
          target_sum: this.storeProduct.price,
          // occasion_id: this.selectedOccasionId,
          name: "Add goal from wishlist section",
        };
        this.addGoal(details);
        this.error = "";
        this.selectedOccasionId = null;
        this.$emit("closeModal", true);
        //   } else {
        //     this.error = "You must select an occasion!";
        //   }
      },
      buyProduct() {
        const productObj = {
          name: this.storeProduct.name,
          description: this.storeProduct.description,
          productPrice: Number(this.storeProduct.price).toFixed(2),
          defaultPicture: this.storeProduct.defaultPicture,
          productId: this.storeProduct.productId,
          from: this.storeProduct.from,
          goalId: this.storeProduct.id,
        };

        console.log("from main page", productObj);

        localStorage.setItem("coinsGame", JSON.stringify(productObj));
        console.log(this.storeProduct.from )

        if (this.storeProduct.from == "wishlist") {
          this.$router.replace({
                name: "children-coins_game_wishlist",
            });
        } else if (this.storeProduct.from == "shop") {
          this.$router.replace({
                name: "children-coins_game_shop",
            });
        } else if (this.storeProduct.from == "goals") {
          this.$router.replace({
                name: "children-coins_game_goals",
            });
        }
      },
      openCheckout() {
        this.selectedSectionLocal = "checkout";
      },
      openCheckoutGame() {
        // this.selectedSectionLocal = "checkoutGame";
      },
      closeModal() {
        this.selectedOccasionId = null;
        this.$emit("closeModal", true);
      },
    },
    // watch: {
    //   "$route.params"() {
    //     if (this.$route.params) {
    //       console.log(this.$route.params);
    //     }
    //   },
    // },
  };
</script>

<style lang="scss" scoped>
  .productPageDetails {
    padding: 0 20px 20px 20px;

    &__title {
      font-size: 16px;
      margin-bottom: 30px;
    }

    &__description {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }

  .appWishlistDetails {
    display: grid;
    grid-template-columns: 1fr 1fr;

    &__left {
      width: 100%;

      &__image {
        width: 100%;
        height: auto;
      }

      &__images {
        padding: 10px;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr 1fr;

        &__small {
          width: 100%;
          height: auto;
          border: 1px solid #dddddd;
          border-radius: 20px;
        }
      }
    }

    &__right {
      width: 100%;
      padding: 30px;

      &__title {
        padding: 10px;

        font-size: 35px;
        line-height: 30px;
        color: #7a615a;
      }

      &__info {
        padding: 10px;
        font-size: 20px;
        line-height: 30px;
        color: #7a615a;
      }

      &__miniGrid {
        display: grid;
        grid-template-columns: 1fr;

        &__price {
          padding: 10px;

          font-size: 35px;
          line-height: 30px;
          // color: #7A615A;
        }
      }

      &__stars {
        width: 100%;

        &__star {
          width: 50px;
          padding: 0px;
        }
      }

      &__ocassions {
        height: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        &__icon {
          height: 20px;
          width: 20px;
          padding: 10px;
          border-radius: 50px;
          margin: 5px;
        }
      }

      &__button {
        margin-top: 10px;
      }
      &__buttons {
        width: 45%;
        margin-top: 10px;
        margin: 5px;
        padding: 5px !important;
        width: 100%;
      }
    }
  }

  // .appWishlistDetails__right__ocassions__icon {
  //   border: 2px solid #DDDDDD !important;
  // }

  .appWishlistDetails__right__ocassions__border {
    // border: 2px solid #ff4f79 !important;
    // background: white !important;
    // background: linear-gradient(
    //   360deg,
    //   #2ccbe8 35.62%,
    //   #6cf9ff 103.95%
    // ) !important;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.2), 0 0 2px 0px rgba(0, 0, 0, 0.2),
      0 0 0 0 #23bcd8, 0 0 2px 5px rgba(255, 255, 255, 0.2);
    background: #7a615a !important;
    // box-shadow: 0 0 0 2px #febf04;
    transform: scale(1.2);
    transition: all 100ms ease-in-out;
  }

  .appWishlistDetails__right__ocassions__border img {
    filter: invert(1) !important;
  }

  .appWishlistDetails__right__ocassions {
    margin-top: 30px;
  }

  .ocassion_holder {
    // border: 2px solid #dddddd;
    border-radius: 100px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ocassion_holder img {
    object-fit: cover;
    width: auto;
    height: 15px;
  }

  .ocassion_item:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: 850px) {
    .appWishlistDetails {
      display: grid;
      grid-template-columns: 1fr;
      // padding: 0px;
      width: auto;
      margin-right: auto;

      &__right {
        margin-left: 0px;
        padding: 0px;
      }
    }
  }

  @media screen and (max-width: 820px) {
    .appWishlistDetails__left__images {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      img {
        height: 60px;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }

  .appWishlistDetails__right__ocassions .ocassion_item:first-child {
    display: none;
  }

  .appWishlistDetails__left__images__small {
    height: 80px !important;
    object-fit: contain !important;
  }

  .appWishlistDetails__left__image {
    height: 300px !important;
    object-fit: contain;
  }

  .missionDays__item {
    user-select: none;
    margin-right: 10px;
    border: 1px solid var(--main);
    border-radius: 100px;

    &__active {
      background: var(--main);
      border: 1px solid var(--main);

      .missionDays__item-username {
        color: white !important;
      }
    }
  }

  .missionDays__item-username {
    color: var(--main);
  }

  .leave-rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .leave-rating,
  .rating-left {
    margin-top: 30px;
    font-size: 18px !important;
    text-align: center;
  }
  .leave-rating .vue-star-rating {
    margin-top: 5px;
  }
  // .leave-rating .vue-star-rating-star svg {
  //   width: 25px !important;
  //   height: 25px !important;
  // }

  .transferInfoTitle {
    width: 100%;
    text-align: center;
    color: #7a615a;
    font-weight: 700;
    margin-bottom: 40px;
    font-size: 18px;
  }

  .amountToTransfer {
    // text-align: center;
    color: #7a615a;
    text-align: center;
    border-top: 1px solid #eee;
    padding: 15px 30px;
    width: auto;
    display: table;
    margin: 15px auto 0 auto;
    align-items: center;
    flex-direction: column;

    span {
      color: #36d5f2;
      font-weight: bold;
    }

    .transferAllAction {
      // background: #36d5f2;
      color: var(--main);
      padding: 5px 0;
      // border-radius: 100px;
      font-size: 16px;
      width: auto;
      display: inline-block;
      margin-top: 15px;
      cursor: pointer;
      font-weight: bold;
      border-bottom: 1px dashed var(--main);
      // box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      //   0 4px 6px -2px rgba(0, 0, 0, 0.05), 0 0 10px rgba(black, 0.1) inset;
    }
  }

  .guardian-login-form {
    height: 500px;
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &__input {
      background-color: #f8f8f8;
      width: 80%;
      padding: 15px;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      outline: none;
      font-size: 16px;
      color: #444 !important;
    }
  }
</style>